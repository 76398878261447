import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import api from "../api/http-common";
import { AppData } from "../common/AppData";
import Dropdown from "../common/Components/Dropdown";
import MultiDisplayDropdown from "../common/Components/MultiDisplayDropdown";
import Textfield from "../common/Components/TextField";
import Extension from "../common/Extension";
import GridTable from "../common/GridTable";
import Loading from "../common/Loading";
import Styles from "../common/Styles";
// import { UserDetailsContext } from "../context/UserDetailsContext";
import { DownloadForOffline } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CustomCommonButton from "../common/Components/CustomCommonButton";
import CustomDateRangePicker from "../common/Components/CustomDateRangePicker";
import "./Inventory.css";
import Logger from "../common/Logger";

const useStyles = makeStyles((theme) => ({
  outlinedButton: {
    border: `2px solid ${`var(--app-bar-color)`}`,
    transition: "color 0.3s ease",
    "&:hover": {
      color: `var(--app-bar-color)`,
    },
  },
}));

const Filter = [
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Today",
    value: "today",
  },
];

const logger = new Logger({ level: "log" });
const Inventory = () => {
  const classes = useStyles();
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");

  // const role_id = userCredentails.userdata.role_id;
  // const branch_id = userCredentails.userdata.branch_id;
  // const entity_Id = userCredentails.entitydata.entity_id;

  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const entity_Id = parseInt(sessionStorage.getItem("entityId"));

  const [branches, setBranches] = useState([]);
  const [inventoryDetails, setInventoryDetails] = useState([]);
  const [items, setItems] = useState([]);
  const [weights, setWeights] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [searchItem, setSearchItem] = useState("All");
  const [selectedItem, setSelectedItem] = useState("All");
  const [cumfilter, setCumFilter] = useState("All");
  const [auctioned, setAuctioned] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);


  const columns = AppData.inventory.map((column) => ({
    ...column,
    headerName: t(`inventory.${column.field}`),
  }));

  const PeriodFilter = Filter.map((fil) => ({
    ...fil,
    label: t(`transaction.${fil.value}`),
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAllData = async () => {
    try {
      handleLoadingOpen();
      const BranchAPI =
        role_id === 1 ? `branch/byEntity/${entity_Id}` : `branch/${branch_id}`;
      const InventoryAPI =
        role_id === 1
          ? `loan/Inventory/${entity_Id}`
          : `/loan/InventoryByBranch/${branch_id}`;
      const InventoryAuctionAPI = `/loan/AuctionedInventory/${entity_Id}`;

      logger.log("Initiate Fetch branch");
      const branchRes = await api.get(BranchAPI);
      logger.log("Fetch branch Successfully");
      setBranches(branchRes.data);

      logger.log("Initiate Fetch inventory data");

      const InventoryDetailRes = await api.get(
        !auctioned ? InventoryAPI : InventoryAuctionAPI
      );
      // console.log(!auctioned ? InventoryAPI : InventoryAuctionAPI)
      // console.log(InventoryDetailRes)
      logger.log("Fetch inventory data Successfully");
      const formattedInventoryDetails = InventoryDetailRes.data.map(item => {
        const formatDate = (dateString) => {
          const [year, month, day] = dateString.split('-');
          return `${day}-${month}-${year}`;
        };
  
        return {
          ...item,
          loan_date: item.loan_date,
          pledged_date: formatDate(item.pledged_date)
        };
      });
  
      setInventoryDetails(formattedInventoryDetails);

      logger.log("Initiate Fetch items");
      const itemRes = await api.get(`loan/items/${entity_Id}`);
      logger.log("Fetch items Successfully");
      setItems(itemRes.data);
      // console.log(InventoryDetailRes)
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  useEffect(() => {
    getAllData();
  }, [auctioned]);

  const handleCumFilter = async (e) => {
    logger.log("Initiate Fetch inventory data");
    const InventoryAPI =
        role_id === 1
          ? `loan/Inventory/${entity_Id}`
          : `/loan/InventoryByBranch/${branch_id}`;
    const transres = await api.get(InventoryAPI);
    logger.log("Fetch inventory data Successfully");
    const transData = transres.data;
    const today = new Date();
    const monthly = format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      "yyyy-MM-dd"
    );
    const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
    const weekly = format(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay() + 1
      ),
      "yyyy-MM-dd"
    );
    const value = e ? e.target.value : "today";

    switch (value) {
      case "weekly":
        const weekData = transData.filter(
          (data) =>
            data.loan_date >= weekly &&
            data.loan_date <= format(today, "yyyy-MM-dd")
        );
        setInventoryDetails(weekData);
        break;
      case "monthly":
        const monthData = transData.filter(
          (data) =>
            data.loan_date >= monthly &&
            data.loan_date <= format(today, "yyyy-MM-dd")
        );
        setInventoryDetails(monthData);
        break;
      case "yearly":
        const yearlyData = transData.filter(
          (data) =>
            data.loan_date >= yearly &&
            data.loan_date <= format(today, "yyyy-MM-dd")
        );
        setInventoryDetails(yearlyData);
        break;
      case "All":
        setInventoryDetails(transData);
        break;
      default:
        const todayData = transData.filter(
          (data) => data.loan_date === format(today, "yyyy-MM-dd")
        );
        setInventoryDetails(todayData);
        break;
    }
    setCumFilter(value);
  };
  const handleDateChange = (values) => {
    if (values && values.length > 0) {
      setDates(values.map((item) => dayjs(item)));
    } else {
      setDates([]);
    }
  };
  const handleBranchChange = async (e) => {
    const branch = e.target.value;
    setSelectedBranch(branch);
    // setCustomers(customer)
  };

  const handleItemChange = (e) => {
    try {
      const itemId = parseInt(e.target.value);
      const data = items.find((data) => parseInt(data.item_id) === itemId);
      setSearchItem(itemId);
      setSelectedItem(data ?? "");
    } catch (error) {
      logger.log(error);
    }
  };

  //table filtration
  const filterRows = inventoryDetails.filter((inventory) => {
    const itemDate = new Date(inventory.loan_date);
    const fromDate = new Date(dates[0]);
    const toDate = new Date(dates[1]);

    const normalizedItemDate = new Date(
      itemDate.getDate(),
      itemDate.getMonth(),
      itemDate.getFullYear()
    );

    const normalizedFromDate = new Date(
      fromDate.getDate(),
      fromDate.getMonth(),
      fromDate.getFullYear()
    );

    const normalizedToDate = new Date(
      toDate.getDate(),
      toDate.getMonth(),
      toDate.getFullYear(),
    );

    return (
      (selectedBranch === "All" ||
        selectedBranch === "" ||
        inventory.shopbranch_id.toString() === selectedBranch.toString()) &&
      (dates.length === 0 ||
        (normalizedItemDate >= normalizedFromDate &&
          normalizedItemDate <= normalizedToDate)) &&
      (selectedItem === "" ||
        selectedItem === "All" ||
        (inventory.item_name.toString() === selectedItem.item_name.toString() &&
          inventory.item_category.toString() ===
            selectedItem.item_category.toString())) &&
      (searchQuery === "" ||
        inventory.customer_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        inventory.customer_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        inventory.customer_num.toString().includes(searchQuery.toString()) ||
        inventory.item_category
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        inventory.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        inventory.item_category_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        inventory.item_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        inventory.branch_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        inventory.branch_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        inventory.loan_num.toString().includes(searchQuery.toString()) ||
        inventory.loan_num.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  //calculate total weights of gold and silver
  const calculateWeights = (row) => {
    const calculateWeight = (category) =>
      row
        .filter((loan) => loan.gold_category_type === category)
        .map((item) => item.total_net_weight)
        .reduce((a, b) => a + b, 0);
    // console.log(calculateWeight)
    const silverWeight = calculateWeight("Not found");
    const n_silver = calculateWeight("");
    const hallMarkWeight = calculateWeight("Hallmark Gold(916)");
    const n_h_Gold = calculateWeight("916");
    const goldWeight = calculateWeight("Normal Gold");
    const n_gold = calculateWeight("normal");
    // console.log(silverWeight)
    return {
      silverWeight: silverWeight + n_silver,
      hallMarkWeight: hallMarkWeight + goldWeight + n_gold + n_h_Gold,
    };
  };

  useEffect(() => {
    const weightData = calculateWeights(filterRows);
    setWeights(weightData);
  }, [filterRows]);

  useEffect(() => {
    const weightData = calculateWeights(filterRows);
    setWeights(weightData);
  }, [cumfilter,searchItem,selectedBranch,selectedItem]);

  const rows = filterRows.map((inv, index) => {
    const isFradulent = inv.fradulent;

    const customerNameStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
    };

    return {
      id: index + 1,
      loanDate:Extension.formatDateDMY(new Date(inv.loan_date)),
      itemName: Extension.languageBasedValue(inv.item_name, inv.item_name_tn),
      itemCat: Extension.languageBasedValue(
        inv.item_category,
        inv.item_category_tn
      ),
      qty: inv.quantity,
      gross: `${inv.total_gross_weight} ${t("common.g")}`,
      net: `${inv.total_net_weight} ${t("common.g")}`,
      rates:
        inv.item_category === "Gold" && inv.gold_category_type === "Normal Gold"
          ? `${Extension.languageBasedValue(
              "Gold",
              "சாதாரண தங்கம்"
            )}: ₹ ${Extension.formatCurrency(inv.item_gold_rate??0)}`
          : inv.item_category === "Gold" &&
            inv.gold_category_type === "Hallmark Gold(916)"
          ? `${Extension.languageBasedValue(
              "916",
              "916"
            )}: ₹ ${Extension.formatCurrency(inv.item_hallmark_rate??0)}`
          : `${Extension.languageBasedValue(
              "Silver",
              "வெள்ளி"
            )}: ₹ ${Extension.formatCurrency(inv.item_silver_rate??0)}`,

      cusId: (
        <Tooltip
          title={Extension.languageBasedValue(
            inv.fradulent_remark,
            inv.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {inv.customer_num}
        </Tooltip>
      ),
      cusName: (
        <Tooltip
          title={Extension.languageBasedValue(
            inv.fradulent_remark,
            inv.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {Extension.languageBasedValue(
            inv.customer_name,
            inv.customer_name_tn
          )}
        </Tooltip>
      ),
      branch: Extension.languageBasedValue(inv.branch_name, inv.branch_name_tn),
      loanId: inv.loan_num,
    };
  });

  const exportToXLSX = () => {
    const dataRows = rows.map((inv) => {
      return {
        [t("inventory.id")]: inv.id,
        [t("inventory.loanDate")]: inv.loanDate,
        [t("inventory.itemName")]: inv.itemName,
        [t("inventory.itemCat")]: inv.itemCat,
        [t("inventory.qty")]: inv.qty,
        [t("inventory.gross")]: inv.gross,
        [t("inventory.net")]: inv.net,
        [t("inventory.rates")]: inv.rates,
        [t("inventory.cusId")]: inv.cusId.props.children,
        [t("inventory.branch")]: inv.branch,
        [t("inventory.loanId")]: inv.loanId,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataRows);
    worksheet["!cols"] = [
      { wch: 5 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "Inventory.xlsx");
  };

  const totalWeights = [
    {
      id: 1,
      label: t("inventory.total gold"),
      weight: weights.hallMarkWeight,
      background:
        "linear-gradient(to bottom, #Ffd700, #ffe030, #fbec93, #FCF1B0, #FFFDEF)",
    },
    {
      id: 2,
      label: t("inventory.silver"),
      weight: weights.silverWeight,
      background:
        "linear-gradient(to bottom, #C0C0C0, #d0d0d0, #dbdbdb, #EFEFEF, #F5F5F5)",
    },
  ];

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };
  useEffect(() => {}, [setAuctioned]);

  return (
    <Paper className="commonpaper">
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Grid
        container
        spacing={{ xs: 2, md: 4, sm: 4 }}
        columns={{ xs: 1, sm: 3, md: 3, lg: 5 }}
        sx={{
          elevation: 3,
          mb: 2,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Grid item xs={1} sm={1} md={1} lg={1}>
          {role_id === 1 ? (
            <Dropdown
              firstOption={t("common.all")}
              label={t("common.select branch")}
              value={selectedBranch}
              onChange={handleBranchChange}
              search={true}
              array={branches}
              menuValue={"branch_id"}
              display={Extension.languageBasedValue(
                "branch_name",
                "branch_name_tn"
              )}
            />
          ) : role_id === 2 ? (
            <Typography
              variant="h5"
              sx={{
                ...Styles.titleText(),
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
            >
              {Extension.languageBasedValue(
                branches.branch_name,
                branches.branch_name_tn
              )}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <MultiDisplayDropdown
            firstOption={t("common.all")}
            label={t("inventory.itemName")}
            search={true}
            value={searchItem}
            onChange={handleItemChange}
            array={items}
            menuValue={"item_id"}
            display={[
              Extension.languageBasedValue("item_name", "item_name_tn"),
              Extension.languageBasedValue("item_category", "item_category_tn"),
            ]}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <Textfield
            label={t("common.search customer")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
        {totalWeights.map((item, index) => (
          <Grid key={item.id} item xs={1} sm={1} md={1} lg={1}>
            <Paper
              elevation={3}
              sx={{
                p: 1,
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: item.background,
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "var(--font-weight)",
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
              >
                {item.label}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CountUp
                  start={0}
                  end={item.weight}
                  duration={2.5}
                  decimals={2}
                  suffix={`(${t("common.gram")})`}
                  style={{ fontSize: "21px" }}
                  useIndianSeparators={true}
                />
              </Box>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <Dropdown
            firstOption={t("transaction.all time")}
            label={t("transaction.custom filter")}
            value={cumfilter}
            onChange={handleCumFilter}
            search={true}
            array={PeriodFilter}
            menuValue={"value"}
            display={"label"}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <CustomDateRangePicker
            label={t("transaction.select range")}
            values={dates}
            onChange={handleDateChange}
          />
        </Grid>

        {role_id === 1 && (
          <Grid item xs={1} sm={1} md={1} lg={2}>
            <ButtonGroup
              // sx={Style.commonButton}
              // color="success"
              value={auctioned}
              exclusive
              variant="outlined"
              aria-label="text alignment"
              color="primary"
            >
              <Button
                // className="button_class_style"
                aria-label="left aligned"
                className={classes.outlinedButton}
                sx={{
                  ...Styles.commonButton(),
                  backgroundColor: !auctioned ? `var(--app-bar-color)` : "",
                  width: 350,
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                  color: auctioned ? `var(--app-bar-color)` : "#FFFFFF",
                }}
                onClick={() => setAuctioned(false)}
                // disabled={!auctioned}
              >
                {t("transaction.active_loan_stocks")}
              </Button>
              <Button
                onClick={() => setAuctioned(true)}
                className={classes.outlinedButton}
                sx={{
                  ...Styles.commonButton(),
                  backgroundColor: auctioned ? `var(--app-bar-color)` : "",
                  width: 350,
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                  color: !auctioned ? `var(--app-bar-color)` : "#FFFFFF",
                }}
                aria-label="centered"
                // disabled={auctioned}
              >
                {t("transaction.auctioned_loan_stocks")}
              </Button>
            </ButtonGroup>
          </Grid>
        )}
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}
        >
          <CustomCommonButton
            text={t("transaction.download")}
            icon={<DownloadForOffline />}
            onClick={exportToXLSX}
          />
        </Grid>
      </Grid>
      <GridTable
        rows={rows}
        columns={columns}
        isLoading={loading}
        height={"70vh"}
      />
    </Paper>
  );
};

export default Inventory;
