import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import api from "../api/http-common";
import LoginImage from "../assets/Gold.jpg";
import SnackBar from "../common/SnackBar";
import Captcha from "./CaptchaGenerator/Captcha";
import LoginAppBar from "./LoginAppBar";
import Logger from "../common/Logger";

const logger = new Logger({ level: "log" });

function Login() {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [captchaSequence, setCaptchaSequence] = useState("");
  const [userInput, setUserInput] = useState("");
  const [subDomain,setSubDomain] = useState("");
  const [fontFamily, setFontFamily] = useState("");
  const [fontSize, setFontSize] = useState("");
  const [appBarColor, setAppBarColor] = useState("");
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [appTheme, setAppTheme] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [headerFontColor, setHeaderFontColor] = useState("");
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);

  const Navigate = useNavigate();
  //auto caps captcha enter
  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase().slice(0, 5);
    setUserInput(value);
  };


  useEffect(() => {
    // Update CSS variable when fontStyle changes
    document.documentElement.style.setProperty(
      "--font-family",
      fontFamily ?? "Arial, sans-serif"
    );
    document.documentElement.style.setProperty(
      "--font-size",
      fontSize ?? "18px"
    );
    document.documentElement.style.setProperty(
      "--app-bar-color",
      appBarColor ?? "#3c5d90"
    );
    document.documentElement.style.setProperty(
      "--header-font-color",
      headerFontColor
    );
    document.documentElement.style.setProperty(
      "--menu-color-1",
      color1 ?? "#D0F0C0"
    );
    document.documentElement.style.setProperty(
      "--menu-color-2",
      color2 ?? "#A0B0D0"
    );
    console.log("@@@@@@@@@@@@@@@@@ ", appTheme);
    document.documentElement.style.setProperty("--font-color", fontColor);
    document.querySelector("body").setAttribute("data-theme", appTheme);
  }, [
    appBarColor,
    appTheme,
    color1,
    color2,
    fontColor,
    fontFamily,
    fontSize,
    headerFontColor,
  ]);

  useEffect(()=>{
     

  });
  //Login function
  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("loginId :",loginId," - password :",password,subDomain)
    if (loginId && password) {
      try {
        // logger.log("Login Initiated");
        const userdata ={
          login_id: loginId,
          password: password,
          sub_domain:subDomain
        }
        console.log(userdata)
        const response = await api
          .post("login",userdata, {
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }})

          .then((res) =>res.data) //get response
          .catch((err) => {
            SnackBar.toastMsg("error", err.response?.data.detail);
            console.log(err)
            logger.log(err);
          });
        // logger.log("Login Successfully");
        const encAccess = CryptoJS.AES.encrypt(
          response.access,
          "jsl_graspear"
        ).toString();
        const encRefresh = CryptoJS.AES.encrypt(
          response.refresh,
          "jsl_graspear"
        ).toString();
        const encLogin = CryptoJS.AES.encrypt(
          loginId,
          "jsl_graspear"
        ).toString();
        const encPassword = CryptoJS.AES.encrypt(
          password,
          "jsl_graspear"
        ).toString();
        if (rememberMe) {
          //store remember me with credentials
          localStorage.setItem("rememberme", true);
          localStorage.setItem("loginId", encLogin);
          localStorage.setItem("password", encPassword);
        } else {
          // If Remember Me is not checked, clear stored credentials from localStorage
          localStorage.removeItem("loginId");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberme");
        }
        sessionStorage.setItem("access", encAccess); //access token
        sessionStorage.setItem("refresh", encRefresh); //refresh token
        const language = localStorage.getItem("language"); //Initially get local storage language
        const setLanguage =
          language === "null" || language == null ? "en" : language; // If is that the new user then set default as English
        localStorage.setItem("language", setLanguage);  
        const userData = await api
          .get(`users/userDetails`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));

        sessionStorage.setItem("roleId", userData.role_id); //For now store data in session
        sessionStorage.setItem("userId", userData.id);
        sessionStorage.setItem(
          "userName",
          userData.first_name + " " + userData.last_name
        );
        sessionStorage.setItem(
          "userName_tn",
          userData.first_name_tn + " " + userData.last_name_tn
        );
        sessionStorage.setItem("branchId", userData.branch_id);
        // sessionStorage.setItem("loginId", userData.login_id);
        sessionStorage.setItem("entityId", userData.entity_id);
        try {
          const response = await api.get(`/themes/${userData.id}`);
          setFontFamily(response.data.font_family);
          setFontSize(response.data.font_size);
          setAppBarColor(response.data.app_bar_color);
          setColor1(response.data.menu_color_1);
          setColor2(response.data.menu_color_2);
          setAppTheme(response.data.theme);
          setFontColor(response.data.font_color);
          setHeaderFontColor(response.data.header_font_color);
        } catch (error) {
          console.error("Error fetching themes:", error);
        }
        const entity = await api
          .get(`entity/${userData.entity_id}`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        sessionStorage.setItem("entityName", entity.name);
        sessionStorage.setItem("entityName_tm", entity.name_tn);
        // const userdata = { userdata: userData, entitydata: entity }; //need to pass through here with context
        // setUserCredentails(userdata);
        if (parseInt(userData.role_id) === 1) {
          Navigate("/dashboard");
        } else if (parseInt(userData.role_id) === 2) {
          Navigate("/managerdashboard");
        } else if (parseInt(userData.role_id) === 3) {
          Navigate("/tenantmaster");
        }
      } catch (error) {
        logger.log(error);
      }
    } else {
      SnackBar.toastMsg("error", "Please enter Login ID and Password");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDataFromChild = (data) => {
    setCaptchaSequence(data);
  };

  const remember_me = localStorage.getItem("rememberme");

  const [rememberMe, setRememberMe] = useState(remember_me ?? false);
  useEffect(() => {
    const url = window.location.href;
    // const url = "https://kvm.jslpro.in/";
    const startIndex = url.indexOf('https://') + 'https://'.length;
    let endIndex = url.indexOf(':', startIndex);
    if (endIndex === -1) {
      endIndex = url.length; // If ':' is not found, consider until the end of the URL
      if (url.slice(-1) === '/') {
        endIndex = url.length - 1;
      }
  }
    const extractedSubdomain = url.substring(startIndex, endIndex);
    setSubDomain(extractedSubdomain);
    const login_Id = localStorage.getItem("loginId");
    const Pass = localStorage.getItem("password");
    if (login_Id && Pass) {
      const storedLoginId = CryptoJS.AES.decrypt(
        localStorage.getItem("loginId"),
        "jsl_graspear"
      ).toString(CryptoJS.enc.Utf8);
      const storedPassword = CryptoJS.AES.decrypt(
        localStorage.getItem("password"),
        "jsl_graspear"
      ).toString(CryptoJS.enc.Utf8);
      if (storedLoginId && storedPassword) {
        setLoginId(storedLoginId);
        setPassword(storedPassword);
      }
    }
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  // Function to handle Remember Me checkbox change
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
        }}
      >
        <LoginAppBar />
      </Box>

      <Box
        sx={{
          height: "100vh",
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", sm: "center", md: "end" },
        }}
      >
        <Toaster />
        <Paper
          elevation={0}
          sx={{
            width: { md: "30%", xs: "90%", sm: "50%" },
            height: "40vh",
            flexDirection: "column",
            mt: "-400px",
            display: "flex",
            backgroundColor: "transparent",
            p: 6,
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontFamily: "cambria", color: "ivory", mb: 2 }}
          >
            Login
          </Typography>
          <Typography
            variant="h7"
            sx={{ fontFamily: "cambria", color: "ivory" }}
          >
            Login ID
          </Typography>
          <TextField
            sx={{
              mb: 3,
              borderRadius: "10px",
              border: "2px solid ivory",
              backgroundColor: "ivory",
              color: "ivory",
            }}
            id="outlined-basic"
            variant="outlined"
            value={loginId}
            size="small"
            required
            InputProps={{
              style: {
                borderRadius: "10px",
              },
              sx: {
                "& input": {
                  color: "common.ivory",
                  fontFamily: "cambria",
                },
              },
            }}
            color="common"
            onChange={(e) => setLoginId(e.target.value)}
            placeholder="Login ID"
          />
          <Typography
            variant="h7"
            sx={{ fontFamily: "cambria", color: "ivory" }}
          >
            Password
          </Typography>
          <TextField
            sx={{
              mb: 3,
              borderRadius: "10px",
              border: "2px solid ivory",
              backgroundColor: "ivory",
              color: "ivory",
            }}
            type={showPassword ? "text" : "password"}
            id="outlined-basic"
            variant="outlined"
            size="small"
            required
            color="common"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "black" }} />
                    ) : (
                      <Visibility sx={{ color: "black" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                borderRadius: "10px",
              },
              sx: {
                "& input": {
                  color: "common.ivory",
                  fontFamily: "cambria",
                },
              },
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <Captcha dataFromCaptcha={handleDataFromChild} />
          <Typography
            variant="h7"
            sx={{ fontFamily: "cambria", color: "ivory" }}
          >
            Enter Captcha
          </Typography>
          <TextField
            sx={{
              mb: 3,
              borderRadius: "10px",
              border: "2px solid ivory",
              backgroundColor: "ivory",
              color: "ivory",
            }}
            id="outlined-basic"
            variant="outlined"
            value={userInput}
            size="small"
            required
            InputProps={{
              style: {
                borderRadius: "10px",
              },
              sx: {
                "& input": {
                  color: "common.ivory",
                  fontFamily: "cambria",
                },
              },
            }}
            color="common"
            onChange={handleInputChange}
            placeholder="Captcha"
          />
          <div>
            <input
              type="checkbox"
              style={{ margin: "10px" }}
              checked={rememberMe}
              onChange={handleRememberMeChange}
            />

            <Typography
              variant="h7"
              sx={{ fontFamily: "cambria", color: "ivory" }}
            >
              Remember Me
            </Typography>
          </div>

          {userInput === captchaSequence ? (
            <Button
              onClick={handleLogin}
              variant="contained"
              color={"primary"}
              sx={{ backgroundColor: "#356BE8", borderRadius: "10px", mt: 2 }}
            >
              Login
            </Button>
          ) : (
            <Button
              onClick={handleLogin}
              variant="contained"
              color={"primary"}
              sx={{
                backgroundColor: "#356BE8",
                borderRadius: "10px",
                mt: 2,
                opacity: 0.5,
                pointerEvents: "none",
              }}
            >
              Login
            </Button>
          )}
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <p
              style={{
                display: "flex",
                marginTop: "8%",
                color: "white",
                justifyContent: "center",
                textAlign: "center",
                fontFamily: "cambria",
              }}
            >
              &copy; Copyright 2024
            </p>
            <p style={{ color: "white", fontFamily: "cambria" }}>
              Graspear Solutions Private Limited.
            </p>
          </div>
        </Paper>
      </Box>
    </>
  );
}

export default Login;